import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Muslim(){
    return(
        <div>
            <br /><br />


            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Gift ideas for Muslim festivals and holidays.</title>
                <meta name="description" content="Learn about Muslim festivals and the best gift ideas for these occasions." />              
                <meta name="keywords" content="gift ideas,islam,muslim festivals,muslim holidays"/>
                <meta property="og:title" content="Our Gift ideas for Muslim festivals and holidays." />        
                <meta property="og:description" content="Learn about Muslim festivals and the best gift ideas for these occasions." />        
                <meta property="og:site_name" content="Muslim festivals" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

            
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Muslim festivals</h1>
            <div className="container px-5 py-1 mx-auto  dark:text-white">
                    <p> Islam is a monotheistic religion founded in the 7th century by the Prophet Muhammad in Mecca. 
                        It is based on the belief in one God (Allah) and the teachings of the Quran, which Muslims consider to be the literal word of God as revealed to Muhammad.
                    </p>
                    <p>
                    The Five Pillars of Islam are fundamental acts of worship and the foundation of a Muslim's faith and practice: <br />

                    &bull; <b>Shahada</b>: Declaration of faith in the oneness of God and the prophethood of Muhammad.<br />

                    &bull; <b>Salat</b>: Performing ritual prayers five times a day.<br />

                    &bull; <b>Zakat</b>: Giving alms to the needy, typically a fixed percentage of income.<br />

                    &bull; <b>Sawm</b>: Fasting during the month of Ramadan.<br /> 

                    &bull; <b>Hajj</b>: Pilgrimage to Mecca at least once in a lifetime, if financially and physically possible.
                    </p>
                    <p>
                    Muslim holidays, such as Eid al-Fitr and Eid al-Adha, are significant for their religious and communal values. 
                    They commemorate important events in Islamic history and provide opportunities for worship, reflection, and community bonding. 
                    These holidays emphasize the principles of faith, charity, and gratitude, reinforcing the spiritual and social fabric of the Muslim community.
                    </p>
                    <p>
                    Exchanging gifts on Muslim holidays symbolizes generosity, gratitude, and the strengthening of social bonds. 
                    It reflects the Islamic values of charity (Zakat) and community support, promoting goodwill and joy among family, friends, and neighbors. 
                    Gifts are often given as expressions of love and appreciation, enhancing the spirit of the festive occasions.
                    </p>
            </div>
                
                <ArticleListing category={"muslim"}></ArticleListing>
            </section>
        </div>
    )
}

export default Muslim;
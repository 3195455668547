import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Buddhist(){
    return(
        <div>
            <br /><br />

            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Gift ideas for Buddhist festivals and holidays.</title>
                <meta name="description" content="Learn about Buddhist festivals and the best gift ideas for these occasions." />              
                <meta name="keywords" content="gift ideas,buddhism,buddhist festivals,buddhist holidays"/>
                <meta property="og:title" content="Our Gift ideas for Buddhist festivals and holidays." />        
                <meta property="og:description" content="Learn about Buddhist festivals and the best gift ideas for these occasions." />        
                <meta property="og:site_name" content="Buddhist festivals" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

           
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Buddhist festivals</h1>
            <div className="container px-5 py-1 mx-auto  dark:text-white">
                    <p> Buddhism is a spiritual tradition and religion founded by Siddhartha Gautama, known as the Buddha, over 2,500 years ago in ancient India. It emphasizes the path to enlightenment through practice, wisdom, and ethical conduct.
                    </p>
                    <p> <b>The Four Noble Truths</b>   are the foundation of Buddhist beliefs, explaining the nature of suffering and the path to its cessation: <br />

&bull; <b>Dukkha</b> : Life is filled with suffering.<br />

&bull; <b>Samudaya</b>: Suffering is caused by desire and attachment.<br />

&bull; <b>Nirodha</b>: Ending desire and attachment ends suffering.<br />

&bull; <b>Magga</b>: The Eightfold Path leads to the end of suffering.</p>
<p>Buddhist holidays commemorate significant events in the life of the Buddha and key moments in the Buddhist calendar. 
    They offer opportunities for reflection, community gathering, and the practice of Buddhist teachings. 
    Celebrations like Vesak honor the Buddha's birth, enlightenment, and passing, reinforcing the teachings and inspiring practitioners.
    </p>
    <p>
    Exchanging gifts on Buddhist holidays symbolizes the values of generosity (dana) and compassion. 
    It fosters a sense of community, gratitude, and the spreading of goodwill. 
    Gifts are often simple and meaningful, reflecting the Buddhist emphasis on non-material values and the cultivation of positive karma.
    </p>
            </div>
                <ArticleListing category={"buddhist"}></ArticleListing>
            </section>
        </div>
    )
}

export default Buddhist;